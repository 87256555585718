@import "colors";

main {
  background: linear-gradient(164deg, rgba(80, 163, 162, 1) 0%, rgba(30, 216, 138, 1) 100%),
  linear-gradient(rgba(255, 255, 255, 0.2) 1px, transparent 1px),
  linear-gradient(to right, rgba(255, 255, 255, 0.2) 1px, transparent 1px);
  background-size: 100% 100%, 80px 80px, 80px 80px;
  background-blend-mode: overlay;
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  overflow: hidden;


  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    background-color: white;
    padding: 2em 5em;
    min-width: 280px;

    img {
      min-height: 60px;
      padding-bottom: 5em;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 7em;

      .login-container-inputs {
        display: flex;
        flex-direction: column;
        gap: 2em;

        label {
          display: flex;
          flex-direction: column;
          font-size: 1.5rem;
          font-family: Raleway-Light, sans-serif;
        }

        input {
          margin-top: 0.3em;
          font-size: 2.5rem;
          padding: 0.2em 1em 0.2em 0.4em;
          border-radius: 5px;
          border: 2px solid $blackBorder;
          font-family: Raleway-Regular, sans-serif;
          transition: 0.3s;
        }

        input:focus {
          outline: $mint;
          border: 2px solid $mint;
        }

        p {
          color: $red;
          margin: 0;
          text-align: center;
          font-size: 1.2rem;
          font-family: Raleway-Regular, sans-serif;
        }
      }

      .login-container-buttonWrapper {
        min-height: 60px;
        display: flex;
        flex-direction: column;

        button {
          background: linear-gradient(164deg, rgba(80, 163, 162, 1) 0%, rgba(30, 216, 138, 1) 100%);
          border: none;
          padding: 0.4em;
          font-size: 2rem;
          color: $white;
          border-radius: 5px;
          font-family: Raleway-Bold, sans-serif;
          cursor: pointer;
          transition: opacity 0.3s;
          width: 100%;
        }

        button:hover {
          opacity: 0.7;
        }

        p {
          margin: 0;
        }

      }


    }

    .googleLoginButton {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2em;
      border: 1px solid $mint;
      width: 100%;
      border-radius: 0.5rem;
      height: 38px;
      a {
        color: $black;
        font-size: 2rem;
        text-decoration: none;
        font-family: Raleway-Regular, sans-serif;
      }

      img {
        width: 23px;
        height: 23px !important;
        margin: 0;
        padding: 0;
        min-height: 0;
      }
    }
  }

  .login-note {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h4 {
      margin: 0;
      font-weight: normal;
      font-family: Raleway-SemiBold, sans-serif;
      font-size: 1.4rem;
    }

    p {
      font-family: Raleway-Medium, sans-serif;
      font-size: 1.2rem;

    }

    h4, p {
      color: white;
    }
  }
}

@media (max-width: 600px) {

  main {
    height: 100%;

    .login-container {
      padding: 2em 2em;
      min-width: 280px;

      img {
        min-height: 45px;
      }

      form {
        max-width: 270px;

        .login-container-inputs {
          display: flex;
          flex-direction: column;
          gap: 2em;

          label {
            font-size: 1.2rem;
          }

          input {
            font-size: 2rem;
          }
        }
      }
    }
  }

}