@import "colors";

.deleteddashboard-container {

  margin-left: 220px;
  width: calc(100% - 220px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 25em;
  gap: 0;
  p {
    font-size: 3rem;
    color: $red;
    font-family: Raleway-SemiBold, sans-serif;
    margin: 0;
  }
}

@media (max-width: 1000px) {

  .deleteddashboard-container {
    margin: 0;
    justify-content: center;
    width: 100%;
  }
}