@import "colors";


.dashboard-loader-wrapper  {
  margin-left: 220px;
  width: calc(100% - 220px);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding-top: 20em;
}
.dashboard-container {
  margin-left: 220px;
  padding: 4em 4em;
  display: flex;
  flex-direction: column;
  gap: 6em;

  h3 {
    color: $white;
    margin: 0 0 0.5em 0.5em;
    font-family: Raleway-Bold, sans-serif;
    font-size: 2.5rem;
    font-weight: normal;
  }
  .category-news {
    color: $red;
    background-color: $redBg;
  }

  .category-sport {
    color: $blue;
    background-color: $blueBg;
  }

  .category-politics {
    color: $orange;
    background-color: $orangeBg;
  }

  .category-businessandfinance {
    color: $purple;
    background-color: $purpleBg;
  }

  .importance-standard {
    color: $mint;
    background-color: $mintBg;
  }

  .importance-important {
    color: #e2ea46;
    background-color: rgba(224, 232, 69, 0.3);
  }

  .importance-urgent {
    color: #f65606;
    background-color: rgba(244, 85, 6, 0.3);
  }

  .category, .importance {
    padding: 0.4em 1em;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    font-size: 1.4rem;
    font-family: Roboto-Regular, sans-serif;
  }

  .dashboard-container-unpublished {
  }
  ul {
    background-color: $bg-colorSecond;
    padding: 2em;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    //gap: 2em;

    .separator {
      height: 2px;
      width: 100%;
      background-color: #7b7b7b;
      margin: 1.5em 0;
    }

    .extra-separator {
      height: 2px;
      width: 100%;
      background-color: #7b7b7b;
      margin:  0 0 1.5em 0;
    }

    .separator-small {
      width: 2px;
      height: 20px;
      background-color: #7b7b7b;
    }

    li:last-of-type + .separator {
      margin-bottom: 0;
    }

    li {
      color: $white;
      list-style: none;
      font-size: 2rem;
      display: flex;
      justify-content: space-between;
      padding: 0.5em 1em;
      border-radius: 5px;
      transition: 0.3s;

      .text-flags-wrapper {
        display: flex;
        gap: 1em;
        justify-content: center;
        align-items: center;
        h4 {
          margin: 0;
          font-weight: normal;
          font-family: Roboto-Medium, sans-serif;
        }
        .flag-container {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.3em;

          span {
            display: flex;
            align-items: center;
          }
        }
      }

      .dashboard-icons-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1em;

        .dashboard-icon {
          
          height: 2.5rem;
          width: auto;
          color: $white;
          transition: 0.3s;
        }
        .pencilIcon {
          height: 2.1rem;
        }
        .copyIcon:hover {
          color: $mint;
        }
        .trashIcon:hover {
          color: $red;
        }
        .pencilIcon:hover {
          color: #FF9C00;
        }
      }
    }

    .li-brak {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      .regenerated-brak {
        color: $yellow;
      }
      .unpublished-brak {
        color: $white;
      }
      p {
        font-size: 3rem;
        margin: 0;
      }
    }

    .li-regeneration {
      color: $yellow;
    }

    li:hover {
      background-color: $hoverTextColor;
      cursor: pointer;

    }

  }


}

@media (max-width: 1000px) {
  .dashboard-loader-wrapper  {
    margin: 0;
    justify-content: center;
    align-items: center;
    //display: none;
    width: 100%;
  }

  .dashboard-container {
    margin: 0;
    padding: 4em 1.5em;

    h3 {
      font-size: 2rem;
      margin-left: 0.3em;
    }
    ul {
      padding: 1em;

      .separator {
        margin: 0.2em 0;
      }

      .extra-separator {
        margin-bottom: 0.2em;
      }
      li {
        padding: 0.5em;
        gap: 1.5em;
        .text-flags-wrapper {
          flex-direction: column-reverse;
          align-items: flex-start;
          gap: 0.5em;
        }
        h4 {
          font-size: 1.6rem;
        }

        .dashboard-icons-wrapper {
          flex-direction: column;

          .separator-small {
            display: none;
          }

          .dashboard-icon {
            height: 2.8rem;
            padding: 0.2em;
          }
        }
      }
      .li-brak {

        p {
          font-size: 2rem;
          margin: 0;
        }
      }
    }
  }


}
