@import "colors";
.generation-entry-page {
  margin-left: 220px;
  width: calc(100% - 220px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10em;
  gap: 3em;

  h3 {
  color: $white;
  margin: 0 0 0.5em 0.5em;
  font-family: Raleway-Bold, sans-serif;
  font-size: 2.5rem;
  font-weight: normal;
  }

  .generation-form {
    display: flex;
    flex-direction: column;
    gap: 3em;
    input {
      background-color: transparent;
      border: 2px solid $grayColor;
      border-radius: 5px;
      padding: 0.5em;
      color: $white;
      transition: 0.3s;
      font-size: 1.8rem;
      font-family: Roboto-Regular, sans-serif;
      min-width: 350px;
    }

    input:focus {
      outline: none;
      border: 2px solid $white;
    }

    button {
      padding: 1em 2em;
      border: 2px solid $grayColor;
      border-radius: 5px;
      background-color: transparent;
      color: $white;
      transition: 0.3s;
      cursor: pointer;
    }

    button:hover {
      background-color: $mintBg;
      border-color: $mint;
    }
  }
  }

@media (max-width: 1000px) {

  .generation-entry-page {
    margin: 0;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  
}
