@import "colors";

.regenerated-details-container {
  margin-left: 220px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 6em 4em;
  color: white;
  gap: 3em;

  .form-element {
    display: flex;
    flex-direction: column;
    width: 100%;
    label {
      color: $white;
      font-size: 1.4rem;
      font-family: Raleway-Regular, sans-serif;
      padding: 0 0 0.3em 0.2em;
    }

    input {
      background-color: transparent;
      border: 2px solid $grayColor;
      border-radius: 5px;
      padding: 0.5em;
      color: $white;
      transition: 0.3s;
      font-size: 1.8rem;
      font-family: Roboto-Regular, sans-serif;
    }

    input:focus {
      outline: none;
      border: 2px solid $white;
    }

    textarea {
      min-height: 230px;
      background-color: transparent;
      border: 2px solid $grayColor;
      border-radius: 5px;
      color: white;
      font-family: Roboto-Regular, sans-serif;
      font-size: 1.8rem;
      line-height: 3.5rem;
      padding: 0.5em;
      transition: 0.3s;
    }

    textarea:focus {
      outline: none;
      border: 2px solid $white;
    }

    .regenerated-textarea {
      border-color: $yellow;
    }
    .regenerated-textarea:focus {
      border-color: $yellow;
    }
    .regenerated-input {
      border-color: $yellow;
    }
    .regenerated-input:focus {
      border-color: $yellow;
    }
  }
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;

    button {
      padding: 1em 2em;
      border: 2px solid $grayColor;
      border-radius: 5px;
      background-color: transparent;
      color: $white;
      transition: 0.3s;
      cursor: pointer;
    }

    .delete-button:hover {
      border-color: $red;
      background-color: $redToast;
    }
    .update-button:hover {
      border-color: $yellow;
      background-color: $yellowToast;
    }
  }
}

@media (max-width: 1000px) {


  .regenerated-details-container {
    margin: 0;
    padding: 4em 1.5em 8em 1.5em;

    .form-element {

      input {
        font-size: 1.5rem;
      }

      textarea {
        font-size: 1.5rem;
      }
    }

    .button-container {
      flex-direction: column-reverse;
      width: 100%;

      button {
        width: 100%;
      }
    }
  }

}